//
// locales/glitch/zh-CN.js
// automatically generated by generateLocalePacks.js
//

import inherited from '../../../app/javascript/mastodon/locales/zh-CN.json';
import messages from '../../../app/javascript/flavours/glitch/locales/zh-CN.json';
import localeData from 'react-intl/locale-data/zh.js';
import { setLocale } from 'locales';

setLocale({
  localeData,
  messages: Object.assign({}, inherited, messages),
});
